<template>
  <div class="background left">
    <router-link to="/" class="left col3 white btn textParagraph"><span class="icon icon-align-text nc-icon nc-icon-24 arrow-left-glyph-24"></span> Go Back </router-link>
    <div class="white incentive textLarge">We have found<br/><span class="pumpkin textBold left textXXLarge"> 8 </span>different ways <br/> to help your website perform better </div>
    <hr/>
    <transition name="slide-up">
    <form id="getQuoteForm" @submit.prevent="submitForm" class="white" v-show="formShow">
      <div class="inputGroup">
        <label for="customerNameInput"> Contact Name </label>
        <div class="input-w-validation">
        <input v-model="form.customerName" id="customerNameInput" type="text" placeholder="ex. John Smith"/>
                <i v-show="errorForm.name" class="nc-icon nc-icon-24 t-warning-glyph-24" v-close-popover="false"
                   v-tooltip="{
  content: 'Please enter your full name',
  show: errorForm.name,
}"></i>
        </div>
      </div>
      <div class="inputGroup">
        <label for="customerPhoneNumberInput"> Phone Number</label>
        <div class="input-w-validation">
        <input v-model="form.customerPhone" id="customerPhoneNumberInput" type="text" placeholder="ex. (949) 321-5432"/>
                <i v-show="errorForm.phone" class="nc-icon nc-icon-24 t-warning-glyph-24" v-close-popover="false"
                   v-tooltip="{
  content: 'Please enter a valid phone number',
  show: errorForm.phone,
}"></i>
        </div>
      </div>
      <div class="inputGroup">
        <label for="customerEmailInput"> Email </label>
        <div class="input-w-validation">
        <input v-model="form.customerEmail" id="customerEmailInput" placeholder=" john@gmail.com" type="email"/>
                <i v-show="errorForm.email" class="nc-icon nc-icon-24 t-warning-glyph-24" v-close-popover="false"
                   v-tooltip="{
  content: 'Please enter a valid email address',
  show: errorForm.email,
}"></i>
        </div>
      </div>
      <div class="inputGroup">
        <button id="getAQuoteSubmitButton" class="primaryButton textMedium textLarge"> Submit</button>
      </div>
    </form>
    </transition>
    <transition name="slide-up">
      <div class="d-block margin-10tb marginTopBottom-15 textNormal white" v-show="!formShow">
        <i class="nc-icon nc-icon-24 f-check-glyph-24 icon-align-text icon-margin-right"></i> Thank you! We will get back to you
        shortly!
      </div>
    </transition>
  </div>
</template>

<script>
import func from '../functions'
export default {
  name: 'evaluationContactForm',
  data: () => {
    return {
      formShow: true,
      form: {
        customerName: '',
        customerPhone: '',
        customerEmail: '',
        customerWebsite: '',
        projectType: 'Evaluation'
      },
      errorForm: {
        name: false,
        phone: false,
        email: false
      }
    }
  },
  methods: {
    validateForm () {
      this.errorForm.name = !func.validateInput(this.form.customerName, 'name')
      this.errorForm.phone = !func.validateInput(this.form.customerPhone, 'phone')
      this.errorForm.email = !func.validateInput(this.form.customerEmail, 'email')
      return !Object.values(this.errorForm).some(i => { return i })
    },
    submitForm () {
      if (this.validateForm()) {
        this.form.customerWebsite = this.$route.params.website
        this.formShow = false
        this.axios.post('/functions/contactForm', this.form)
          .then(() => {
            console.log('you are all set!')
          })
          .catch(e => console.log(e))
      }
    }
  }
}
</script>

<style scoped>
 .background{
   background: linear-gradient(#6850F5, #FFAA00);
   height: 100%;
   display: block;
   padding: 20px;
   min-height: 700px;
 }
  .incentive {
    margin-top: 10px;
    height: 100px;
    display: block;
    text-align: left;
    justify-content: left;
    position: relative;
  }
  .slide-up-leave{
    height: 620px;
  }
  .slide-up-enter, .slide-up-leave-to {
    height: 0;
    opacity: 0;
    overflow: hidden;

  }
  .slide-up-enter-active, .slide-up-leave-active {
    transition: height 0.5s ease-out, opacity 0.5s ease-in;
  }
</style>
