<template>
  <div id="body" data-view>
    <header class="header-background">
      <div class="section d-block header">
        <div class="blackCover">
          <div class="main-content flex-wrap">
            <div class="top-bar col12">
              <div class="logoPlaceHolder d-block">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 405.907 112.258">
                  <title>Soli Technology LLC</title>
                  <path
                    d="m87.581 111.53v-33.105h13.778l15.073-18.978h-50.833l-12.809 18.978h12.406v33.128zm-11.762-111.69-53.958 78.583h25.23l28.727-41.864v-36.696zm-63.029 92.046h48.848v19.642h-61.634zm77.862 0v19.642h61.68l-32.968-48.985-13.794 16.437 7.2574 12.907zm-7.4978-44.652h25.649l-21.229-13.61 21.229 13.648-32.984-47.394v36.696l7.3349 10.698z"
                    fill="#fff" fill-rule="evenodd"/>
                  <path
                    d="m175.37 40.9a6.9361 6.9361 0 0 0 -4.5477 1.3741 4.4414 4.4414 0 0 0 -1.6359 3.6235 4.0897 4.0897 0 0 0 2.0367 3.5907q2.0367 1.3332 9.4063 3.1491 7.3696 1.8158 11.451 5.4393 4.0897 3.6316 4.0897 10.584a13.995 13.995 0 0 1 -5.2184 11.296q-5.2266 4.3269-13.733 4.3269-12.269 0-22.142-9.1036l6.887-8.4411q8.3675 7.3614 15.475 7.3614a8.1794 8.1794 0 0 0 4.8913 -1.3987 4.4005 4.4005 0 0 0 1.8076 -3.7053 4.3187 4.3187 0 0 0 -1.9222 -3.7053q-1.9222-1.366-7.6232-2.7728-8.9973-2.143-13.218-5.5865-4.2206-3.4435-4.1797-10.813 0-7.3614 5.292-11.361 5.2921-3.9997 13.218-3.9997a31.99 31.99 0 0 1 10.363 1.7749 27.761 27.761 0 0 1 8.9973 5.0385l-5.791 8.4411q-6.7316-5.1121-13.905-5.1121zm70.187 16.62a16.956 16.956 0 0 0 -4.4823 -11.811 14.183 14.183 0 0 0 -10.903 -4.9076 14.183 14.183 0 0 0 -10.919 4.9076 16.956 16.956 0 0 0 -4.4414 11.811 16.8 16.8 0 0 0 4.4414 11.77 14.24 14.24 0 0 0 10.919 4.8504 14.24 14.24 0 0 0 10.919 -4.8504 16.8 16.8 0 0 0 4.4414 -11.77zm3.9179 19.025a26.567 26.567 0 0 1 -19.32 7.6641 26.567 26.567 0 0 1 -19.32 -7.6641 25.544 25.544 0 0 1 -7.8522 -19.058 25.544 25.544 0 0 1 7.8522 -19.066 26.567 26.567 0 0 1 19.32 -7.6641 26.567 26.567 0 0 1 19.32 7.6641 25.544 25.544 0 0 1 7.8522 19.066 25.544 25.544 0 0 1 -7.8522 19.058zm17.627 7.1488v-51.751h11.549v41.461h22.084v10.29zm42.05-51.751h11.549v51.751h-11.59v-51.751z"
                    fill="#fff" fill-rule="evenodd"/>
                  <path
                    d="m173.75 105.43h1.1363v-13.734h5.3427v-0.98808h-11.822v1.0163h5.3427v13.734zm9.7468-13.72h9.0763v-1.0728h-10.191v14.821h10.445v-1.101h-9.3303v-5.8226h8.6528v-1.0304h-8.6528v-5.7662zm23.291 10.954a6.5849 6.5849 0 0 1 -4.6228 1.8774c-4.2346 0-6.4578-2.9925-6.5002-6.4578-0.0423-3.4653 2.195-6.5778 6.5002-6.5778a6.4437 6.4437 0 0 1 4.6016 1.8774l0.7834-0.70577a7.6294 7.6294 0 0 0 -5.385 -2.2161c-5.0604 0-7.6365 3.6912-7.5941 7.6365 0.0423 3.9453 2.6184 7.4741 7.5941 7.4741a7.6294 7.6294 0 0 0 5.371 -2.1597l-0.76224-0.70577zm15.132 2.7666v-14.821h-1.1575v7.0577h-9.408v-7.0577h-1.1434v14.821h1.1434v-6.7119h9.408v6.7119zm3.2677-14.821v14.821h1.1434v-12.217l9.9585 12.217h0.43759v-14.821h-1.1151v12.238l-9.9514-12.21zm21.879-0.21174c-4.9828 0-7.453 3.6277-7.453 7.5941 0 3.9665 2.4914 7.6647 7.453 7.6647 4.9616 0 7.4036-3.7053 7.4036-7.5941 0-3.8888-2.4702-7.6365-7.4459-7.6365zm0 1.0587c4.2346 0 6.352 3.1407 6.352 6.5566 0 3.416-2.1173 6.5849-6.352 6.5849-4.2346 0-6.3096-3.2466-6.352-6.5849 0-3.4795 2.1173-6.5566 6.352-6.5566zm10.149-0.84692v14.821h9.4503v-1.0798h-8.3422v-13.713zm17.976-0.21174c-4.9828 0-7.4459 3.6277-7.4459 7.5941 0 3.9665 2.4843 7.6647 7.4459 7.6647 4.9616 0 7.4248-3.7336 7.4459-7.6224 0.0211-3.8888-2.4632-7.6365-7.4459-7.6365zm0 1.0587c4.2346 0 6.352 3.1407 6.352 6.5566 0 3.416-2.1173 6.5849-6.352 6.5849-4.2346 0-6.3096-3.2466-6.352-6.5849 0-3.4795 2.1173-6.5566 6.352-6.5566zm22.43 1.1786a7.6929 7.6929 0 0 0 -5.3992 -2.2161c-4.4958 0-7.6435 3.0207-7.6224 7.6365 0 4.03 2.5972 7.5377 7.6224 7.5377a7.6294 7.6294 0 0 0 5.5686 -2.3855v-5.5262h-6.2249v0.98808h5.1522v4.1358a6.6555 6.6555 0 0 1 -4.4958 1.708c-4.3052 0-6.5214-2.9925-6.5214-6.479 0-3.4865 2.195-6.5778 6.5214-6.5778a6.8319 6.8319 0 0 1 4.7428 1.8985l0.65637-0.70577zm6.8601 12.767h1.1363v-5.9144l5.7874-8.8575h-1.3339l-5.018 7.7635-5.004-7.7635h-1.3269l5.7591 8.8575z"
                    fill="#fff" fill-rule="evenodd"/>
                </svg>
              </div>
              <div class="desktop menu-buttons col4">
                <a href="tel:949-310-1960" class="btn white">Phone (323) 989-2646</a>
                <button class="btn btnGlyph textParagraph black" v-scroll-to="'#contact'">Contact us</button>
                <button class="btn btnOutline textParagraph" v-scroll-to="'#portfolio'">Portfolio</button>
              </div>
            </div>
            <div class="headerText">
              <div class="headerSmallText col12">
                <div class="textParagraph white">
                  WE DESIGN AND DEVELOP
                </div>
              </div>
              <div class="headerLargeText col12">
                <h1 class="textBold white">
                  Software, Web and Mobile Applications
                </h1>
              </div>
              <div class="formSize ">
                <transition name="slide-text">
                  <div class="headerButtons slide-text" v-show="actionShow">
                    <form @submit.prevent="submitEvaluationForm">
                      <label for="evalInput" class="col12">Enter your website URL for a <span
                        class="textBold tangerine">FREE</span>, <br class="mobile"/>no-commitment
                        evaluation</label>
                      <div class="input-w-validation">
                        <input id="evalInput" v-model="evalURL" type="text" v-on:focus="removeBottomBar"
                               v-on:focusout="bottomBarVisible = true" class="marginTopBottom-15 col12"
                               placeholder="Enter website URL"/><i v-show="evalURLError"
                                                                   class="nc-icon nc-icon-24 t-warning-glyph-24"
                                                                   v-close-popover="false" v-tooltip="{
                                                               content: 'Please enter a valid URL',
                                                               show: evalURLError
                                                               }"></i>
                      </div>
                      <button id="evalBtn" type="submit" class="primaryButton col12">Evaluate My Site</button>
                    </form>
                  </div>
                </transition>
              </div>
              <div class="desktop margin-20tb d-block col3">
                <button class="btn btnGlyph col5 textNormal black" v-scroll-to="'#contact'">Contact us</button>
                <button class="btn btnOutline col5 textNormal" v-scroll-to="'#portfolio'">Portfolio</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <section class="section">
      <div class="container">
        <div class="howWeWork main-content padding-20tb">
          <div class="howWeWorkTitle">
            <h3 class="textXLarge textMedium">
              Dream to<span class="pumpkin"> Reality. </span>
            </h3>
            <p class="textParagraph textSemiMute">
              Whether you are a start up or an enterprise to renovate,<br class="mobile"/> We help you.
            </p>
          </div>
          <div class="howWeWorkSteps">
            <i class="nc-icon nc-icon-48 design"></i>
            <div class="caption">
              <h3 class="textNormal textMedium"> Design </h3>
              <p class="textParagraph textSemiMute margin-10tb">We have a dedicated designer who will work with you
                until you
                are happy with your web/app design.</p>
            </div>
          </div>
          <div class="howWeWorkSteps">
            <i class="nc-icon nc-icon-48 code"></i>
            <div class="caption">
              <h3 class="textNormal textMedium"> Development </h3>
              <p class="textParagraph textSemiMute margin-10tb"> Our engineer uses cutting edge technology to deliver
                faster,
                reliable and cost effective applications. </p>
            </div>
          </div>
          <div class="howWeWorkSteps">
            <i class="nc-icon nc-icon-48 home-in-laptop"></i>
            <div class="caption">
              <h3 class="textNormal textMedium"> Delivery </h3>
              <p class="textParagraph textSemiMute margin-10tb"> We ensure customer satisfaction by including 2 weeks of
                debugging
                and testing after application delivery.</p>
            </div>
          </div>
        </div>
        <div class="ourPortfolio main-content padding-20tb">
          <div class="headerTeam">
            <h3 id="portfolio" class="textXLarge textMedium">Our <span class="pumpkin">Portfolio.</span></h3>
          </div>
          <portfolio :portfolio="portfolio"></portfolio>
        </div>
        <div class="ourTeam main-content padding-20tb">
          <div class="headerTeam">
            <h3 class="textXLarge textMedium">Our <span class="pumpkin">Team.</span></h3>
          </div>
          <div class="teammateCard">
            <div class="teammateAvatar leftAvatar">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
                <circle class="svgCircle" fill="transparent" stroke-width="10" stroke="#8EC0F9" cx="80" cy="80" r="75"/>
              </svg>
              <img v-lazy="'images/amin.jpg'" alt="Amin Soltani"/>
            </div>
            <div class="teammateCaption">
              <h4 class="teammateName textLarge textMedium"> Amin Soltani </h4>
              <h5 class="teammateTitle blue textNormal textBold"> CEO & Founder </h5>
              <div class="socialIcons"><a href="mailto:amin@solitechnology.com">
                <i class="nc-icon nc-icon-24 plane iconEmail"></i>
              </a>
                <a href="https://www.linkedin.com/in/aminsoltani2646">
                <i class="nc-icon nc-icon-24 linkedin iconLinkedin icon-align-text"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="teammateCard">
            <div class="teammateAvatar rightAvatar">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
                <circle class="svgCircle" fill="transparent" stroke-width="10" stroke="#ECD29F" cx="80" cy="80" r="75"/>
              </svg>
              <img v-lazy="'images/betsa.jpg'" alt="Betsabeh Yeganeh"/>
            </div>
            <div class="teammateCaption">
              <h4 class="teammateName textLarge textMedium"> Betsabeh Yeganeh </h4>
              <h5 class="teammateTitle di-serria textNormal textBold"> UI UX Designer </h5>
              <div class="socialIcons"><a href="mailto:betsa@solitechnology.com">
                <i class="nc-icon nc-icon-24 plane iconEmail"></i>
              </a>
                <a href="https://www.linkedin.com/in/aminsoltani2646">
                <i class="nc-icon nc-icon-24 linkedin iconLinkedin icon-align-text"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="teammateCard">
            <div class="teammateAvatar centerAvatar">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
                <circle class="svgCircle" fill="transparent" stroke-width="10" stroke="#CCB0F9" cx="80" cy="80" r="75"/>
              </svg>
              <img v-lazy="'images/darya.jpg'" alt="Darya Hajimousa"/>
            </div>
            <div class="teammateCaption">
              <h4 class="teammateName textLarge textMedium"> Darya Hajimousa </h4>
              <h5 class="teammateTitle purple textNormal textBold"> Social Media Manager </h5>
              <div class="socialIcons"><a href="mailto:amin@solitechnology.com">
                <i class="nc-icon nc-icon-24 plane iconEmail"></i>
              </a>
                <a href="https://www.linkedin.com/in/aminsoltani2646">
                <i class="nc-icon nc-icon-24 linkedin iconLinkedin icon-align-text"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="getAQuote main-content padding-20tb">
          <div class="contact-us-form">
            <h3 id="contact" class="textMedium textXLarge left"> Get a <span class="pumpkin">Quote.</span></h3>
            <div class="left textNormalPlus"><a class="black no-decoration" href="tel:(323) 989-2646"><i
              class="nc-icon nc-icon-24 phone icon-align-text icon-margin-right"></i> Phone: (323) 989-2646</a></div>
            <hr/>
            <div class="left textNormalPlus"><a class="black no-decoration" href="mailto:hello@solitechnology.com"><i
              class="nc-icon nc-icon-24 at-sign icon-align-text icon-margin-right"></i> Email: hello@solitechnology.com</a>
            </div>
            <hr/>
            <div class="left textSemiMute marginTopBottom-15">Or message us now...</div>
            <transition name="slide-up">
              <form id="getQuoteForm" @submit.prevent="submitQuoteRequest" v-show="quoteForm">
                <div class="inputGroup">
                  <label for="projectTypeInput" class="textNormal">What do you want to build?</label>
                  <div class="input-w-validation">
                    <select v-model="getAQuote.projectType" id="projectTypeInput" name="projectType">
                      <option value="" disabled selected> Select your project</option>
                      <option> Web Application</option>
                      <option> Mobile Application</option>
                      <option> Software Integration</option>
                      <option> Custom Software</option>
                    </select>
                    <i v-show="getAQuoteError.projectType" class="nc-icon nc-icon-24 t-warning-glyph-24"
                       v-close-popover="false"
                       v-tooltip="{
  content: 'Please select your project',
  show: getAQuoteError.projectType,
}"></i>
                  </div>
                </div>
                <div class="inputGroup">
                  <label for="customerNameInput"> Your Name </label>
                  <div class="input-w-validation">
                    <input id="customerNameInput" v-model="getAQuote.customerName" type="text"
                           placeholder="ex. John Smith"
                           v-on:focus="removeBottomBar" v-on:focusout="bottomBarVisible = true"/>
                    <i v-show="getAQuoteError.customerName" class="nc-icon nc-icon-24 t-warning-glyph-24"
                       v-close-popover="false"
                       v-tooltip="{
  content: 'Please enter your customerName',
  show: getAQuoteError.customerName,
}"></i>
                  </div>
                </div>
                <div class="inputGroup">
                  <label for="customerPhoneNumberInput"> Phone Number to Contact you </label>
                  <div class="input-w-validation">
                    <input id="customerPhoneNumberInput" v-model="getAQuote.customerPhone" type="text"
                           placeholder="ex. (949) 321-5432"
                           v-on:focus="removeBottomBar" v-on:focusout="bottomBarVisible = true"/>
                    <i v-show="getAQuoteError.customerPhone" class="nc-icon nc-icon-24 t-warning-glyph-24"
                       v-close-popover="false"
                       v-tooltip="{
  content: 'Please enter a valid customerPhone number',
  show: getAQuoteError.customerPhone,
}"></i>
                  </div>
                </div>
                <div class="inputGroup">
                  <label for="customerPhoneNumberInput"> Email address </label>
                  <div class="input-w-validation">
                    <input id="customerEmailInput" v-model="getAQuote.customerEmail" type="text"
                           placeholder="ex. hello@gmail.com"
                           v-on:focus="removeBottomBar" v-on:focusout="bottomBarVisible = true"/>
                    <i v-show="getAQuoteError.customerEmail" class="nc-icon nc-icon-24 t-warning-glyph-24"
                       v-close-popover="false"
                       v-tooltip="{
  content: 'Please enter a valid customerEmail address',
  show: getAQuoteError.customerEmail,
}"></i>
                  </div>
                </div>
                <div class="inputGroup">
                  <label for="projectDescription"> Tell us more about your project </label>
                  <textarea id="projectDescription" v-model="getAQuote.customerNote"
                            v-on:focus="removeBottomBar" v-on:focusout="bottomBarVisible = true"></textarea>
                </div>
                <div class="inputGroup getAQuoteButton">
                  <button id="getAQuoteSubmitButton" v-on:focus="removeBottomBar"
                          v-on:focusout="bottomBarVisible = true" class="primaryButton textMedium textLarge"> Get a
                    Quote
                  </button>
                </div>

              </form>
            </transition>
            <transition name="slide-up">
              <div class="d-block margin-10tb marginTopBottom-15 textNormalPlus" v-show="!quoteForm">
                <i class="nc-icon nc-icon-24 f-check-glyph-24 icon-align-text icon-margin-right"></i> Thank you! We will
                get back to you shortly!
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div id='bottomBar' v-if="bottomBarVisible" class="bottomBar col12">
        <div class="bottomBarItemList col12">
          <button v-scroll-to="'#portfolio'" tag="button" class="bottomBarItem textLarge col6 viking textBold">
            Portfolio
          </button>
          <button v-scroll-to="'#contact'" tag="button" class="bottomBarItem textLarge col6 viking textBold"> Contact
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import portfolio from '../components/portfolio'
import func from '../functions'

export default {
  name: 'home',
  components: { portfolio },
  data: () => {
    return {
      bottomBarVisible: true,
      actionShow: false,
      quoteForm: true,
      portfolio: [
        {
          name: 'Darya Restaurant',
          image: '/images/portfolio/DaryaRestaurantSmall.jpg',
          caption: 'Web Application',
          link: 'http://www.daryarestaurant.com'
        },
        {
          name: 'YayorNay',
          image: '/images/portfolio/YayorNaySmall.jpg',
          caption: 'Mobile Application',
          link: '#'
        },
        {
          name: 'Olaplex',
          image: '/images/portfolio/OlaplexSmall.jpg',
          caption: 'Web Application',
          link: 'https://olaplex.com'
        }
      ],
      evalURL: '',
      evalURLError: false,
      getAQuote: {
        projectType: '',
        customerName: '',
        customerPhone: '',
        customerEmail: '',
        customerNote: ''
      },
      getAQuoteError: {
        projectType: false,
        customerName: false,
        customerPhone: false,
        customerEmail: false,
        customerNote: false
      }
    }
  },
  methods: {
    removeBottomBar (event) {
      this.bottomBarVisible = false
      event.target.scrollIntoView({
        block: 'nearest'
      })
    },
    showAction () {
      this.actionShow = true
    },
    submitEvaluationForm () {
      this.evalURLError = false
      this.evalURLError = !func.validateInput(this.evalURL, 'domain')
      if (!this.evalURLError) {
        this.$router.push({ name: 'evaluationContactForm', params: { website: this.evalURL } })
      }
    },
    validateGetAQuoteFrom () {
      this.getAQuoteError.projectType = !func.validateInput(this.getAQuote.projectType, 'name')
      this.getAQuoteError.customerName = !func.validateInput(this.getAQuote.customerName, 'name')
      this.getAQuoteError.customerPhone = !func.validateInput(this.getAQuote.customerPhone, 'phone')
      this.getAQuoteError.customerEmail = !func.validateInput(this.getAQuote.customerEmail, 'email')
      return !Object.values(this.getAQuoteError).some(i => { return i })
    },
    submitQuoteRequest: function () {
      if (this.validateGetAQuoteFrom()) {
        this.quoteForm = false
        this.axios.post('/functions/contactForm', this.getAQuote)
          .then(() => {
            console.log('you are all set!')
          })
          .catch(e => console.log(e))
      }
    }
  },
  created () {
    setTimeout(this.showAction, 100)
  }
}
</script>
<style scoped>
  .slide-text {
    overflow: hidden;
    position: relative;
    top: 0;
    opacity: 1;
  }

  .slide-text-enter {
    top: -300px;
    opacity: 0;
  }

  .slide-text-enter-active {
    transition: top ease-out 2s, opacity ease-out 2s;
  }
  .slide-up-leave{
    height: 620px;
  }
  .slide-up-enter, .slide-up-leave-to {
    height: 0;
    opacity: 0;
    overflow: hidden;

  }
  .slide-up-enter-active, .slide-up-leave-active {
    transition: height 0.5s ease-out, opacity 0.5s ease-in;
  }
</style>
