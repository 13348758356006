import Vue from 'vue'

import App from './App.vue'
import router from './router'
import VueScrollTo from 'vue-scrollto'
import VTooltip from 'v-tooltip'
import VueLazyload from 'vue-lazyload'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.config.productionTip = false
// You can also pass in the default options

Vue.use(VTooltip)
Vue.use(VueLazyload, { preLoad: 1.2 })
Vue.use(VueAxios, axios)
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
