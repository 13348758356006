<template>
  <div class="portfolioHolder">
    <div class="portfolioCards">
        <div v-for="work in portfolio" :key="work.name" class="portfolioCard">
          <img v-lazy="work.image" alt="First work">
          <div class="caption">
            <span>{{work.name}}</span>
            <span class="textSemiMute textParagraph">{{work.caption}}</span>
            <a :href="work.link" class="pumpkin"> Visit the website </a>
          </div>
        </div>
      <div class="extraWidth20 mobile">&nbsp;</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'portfolio',
  props: ['portfolio']
}
</script>

<style scoped>

</style>
