import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home'
import evaluationContactForm from './views/evaluationContactForm'

Vue.use(Router)

const routes = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        title: 'Home - Soli Technology LLC'
      }
    },
    {
      path: '/evaluationContactForm',
      name: 'evaluationContactForm',
      component: evaluationContactForm,
      meta: {
        title: 'Free Evaluation - Soli Technology LLC'
      }
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  }
})

routes.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

export default routes
