export default {
  validateInput: (value, validationName, options = {}) => {
    const validations = {
      domain: new RegExp('^(https?:\\/\\/|www\\.|https?:\\/\\/www\\.)?' + // protocol
        '(([a-z\\d]([a-z\\d-]*[a-z\\d])*))+[a-z]{2,}\\.[a-z]{2,}', 'i'),
      name: new RegExp('^[a-z]{3,}', 'i'),
      phone: new RegExp('^(\\d{3})\\D?\\D?(\\d{3})\\D?(\\d{4})$', 'i'),
      email: new RegExp('^[\\w+.]+@[a-zA-Z_]+?\\.[a-zA-Z]{2,4}$', 'i')
    }
    if (validationName in validations) {
      const pattern = validations[validationName]
      return pattern.test(value)
    }
  }
}
